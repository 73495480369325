import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Container from 'reactstrap/lib/Container';

// Models
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { bindToContent, CmsEntities } from 'client/data/models/cms';

// Utils
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { TrackingHandler } from 'client/tracking/handler';
import { EditorialMap } from 'client/tracking/maps/editorial';

// Components
import { CarNewsLandingHeader } from 'site-modules/editorial/components/car-news/car-news-landing-header/car-news-landing-header';
import { CarNewsSection } from 'site-modules/editorial/components/car-news/car-news-section/car-news-section';
import { YoutubeVideoModalAsync } from 'site-modules/shared/components/youtube-video-modal/youtube-video-modal-async';
import { PageLinksWidget } from 'site-modules/shared/components/page-links-widget/page-links-widget';
import { IframeModal } from 'site-modules/shared/components/iframe-modal/iframe-modal';

// Constants
import { CAR_NEWS_LANDING_CONTENT_PATH } from 'site-modules/shared/constants/editorial/editorial';

function CarNewsLandingUI({ content, isMobile, enableMarketBrewTestBestType }) {
  const [videoModalData, setVideoModalData] = useState({});
  const [isVideoModalOpen, toggleVideoModalOpen] = useToggle(false);
  const [iframeModalData, setIframeModalData] = useState({});
  const [isIframeModalOpen, toggleIframeModalOpen] = useToggle(false);

  useEffect(() => {
    TrackingHandler.useMap(EditorialMap);
  }, []);

  const onToggleVideoModal = useCallback(newVideoModalData => {
    toggleVideoModalOpen();
    setVideoModalData(newVideoModalData || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleIframeModal = useCallback(newIframeModalData => {
    toggleIframeModalOpen();
    setIframeModalData(newIframeModalData || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!content) {
    return null;
  }

  const { title } = content;
  const mainEntry = content.child('main');
  const subnavEntry = content.child('subnav');
  const anchorNavList = subnavEntry.links().map(({ href, title: name }, index) => ({
    name,
    hash: href.replace('#', ''),
    customOffset: index === 0 ? -300 : null,
  }));

  return (
    <main id="main-content">
      <CarNewsLandingHeader title={title} navList={anchorNavList} isMobile={isMobile} />
      {mainEntry.children().map(entry => (
        <CarNewsSection
          key={entry.id}
          content={entry}
          openVideoModal={onToggleVideoModal}
          openIframeModal={onToggleIframeModal}
          isMobile={isMobile}
          enableMarketBrewTestBestType={enableMarketBrewTestBestType}
        />
      ))}
      <Container>
        <PageLinksWidget divideSections={false} title="Related Information" hasTopDivider />
      </Container>
      <YoutubeVideoModalAsync
        onToggleModal={onToggleVideoModal}
        isOpen={isVideoModalOpen}
        videoId={videoModalData.videoId}
        videoAspectRatio={videoModalData.videoAspectRatio}
        creativeId={videoModalData.creativeId}
        videoTitle={videoModalData.videoTitle}
      />
      <IframeModal
        onToggleModal={onToggleIframeModal}
        isOpen={isIframeModalOpen}
        iframeCode={iframeModalData.iframeCode}
      />
    </main>
  );
}

CarNewsLandingUI.propTypes = {
  isMobile: PropTypes.bool,
  content: CmsEntities.Content,
  enableMarketBrewTestBestType: PropTypes.bool,
};

CarNewsLandingUI.defaultProps = {
  isMobile: false,
  content: null,
  enableMarketBrewTestBestType: false,
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
  enableMarketBrewTestBestType: !!get(state, 'featureFlags["enable-market-brew-test-best-type"]'),
});

export const CarNewsLanding = connect(mapStateToProps)(
  connectToModel(CarNewsLandingUI, {
    content: bindToContent(CAR_NEWS_LANDING_CONTENT_PATH),
  })
);
