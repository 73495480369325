import classnames from 'classnames';
import { get, flatten, compact } from 'lodash';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { getModelState } from 'client/data/luckdragon/model';
import { CmsModel } from 'client/data/models/cms';
import { getPageLinksPath, LinkWidgetsModel } from 'client/data/models/link-widgets';
import { PageModel } from 'client/data/models/page';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import { pageDefinition } from 'site-modules/shared/pages/page';
import { parseContent } from 'client/data/cms/content';
import { getMeta, getSeoImage } from 'site-modules/shared/utils/seo-helper';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { getBaseBreadcrumb, getHomeBreadcrumb } from 'site-modules/shared/components/breadcrumbs/helper';
import { getCarNewsArticleSchema } from 'client/seo/schemas/article';
import { getVideoSchema } from 'client/seo/schemas/video';
import { getFullImageUrl } from 'client/utils/image-helpers';
import { setCriticalCss } from 'client/actions/critical-css';
import {
  CAR_NEWS_LANDING_CONTENT_PATH,
  EV_NEWS_WIDGET_CONTENT_PATH,
} from 'client/site-modules/shared/constants/editorial/editorial';
import { CarNewsLanding } from './index';

export const pageOptions = {
  name: 'car_news_industry_article_index',
  category: 'car-news',
  preload: store => {
    const preloader = new ModelPreloader(store);

    preloader.resolve(`content["${CAR_NEWS_LANDING_CONTENT_PATH}"]`, CmsModel);
    preloader.resolve(`content["${EV_NEWS_WIDGET_CONTENT_PATH}"]`, CmsModel);
    preloader.resolve(getPageLinksPath(), LinkWidgetsModel);

    return preloader.load().then(stateModel => {
      const { mobile } = store.getState();
      const content = parseContent(stateModel.get(`content["${CAR_NEWS_LANDING_CONTENT_PATH}"]`, CmsModel));
      const firstTopStoryEntry = content
        .child('main')
        .child('top-stories')
        .child('top-stories-1');
      const firstArticleImgLink =
        firstTopStoryEntry
          .metadata('media-image')
          .value('')
          .replace('300.jpg', classnames({ '300.jpg': mobile, '717.jpg': !mobile })) ||
        getFullImageUrl(firstTopStoryEntry.metadata('thumbnailURL').value(''));
      const preloadHeroImageAsset = {
        href: firstArticleImgLink,
        as: 'image',
      };

      preloader.set('server.preloadedAssets', PageModel, [preloadHeroImageAsset]);

      store.dispatch(
        setCriticalCss({
          topRatedPromo: !!stateModel.get('top-rated-promo', FeatureFlagModel),
        })
      );

      return preloader.load();
    });
  },
  seo: state => {
    const content = parseContent(getModelState(state).get(`content["${CAR_NEWS_LANDING_CONTENT_PATH}"]`, CmsModel));
    const title = get(content, 'title', '');
    const mainEntry = content.child('main');

    return {
      ...getMeta(content),
      jsonld: compact(
        flatten([
          getBreadcrumbSchema([getHomeBreadcrumb(), getBaseBreadcrumb(title, '/car-news/')]),
          mainEntry
            .child('top-stories')
            .children()
            .map(entry =>
              entry.hasMetadata('videoId') ? getVideoSchema(entry.getAllMetadata()) : getCarNewsArticleSchema(entry)
            ),
          mainEntry
            .child('car-news')
            .children()
            .map(entry => getCarNewsArticleSchema(entry)),
          mainEntry
            .child('video')
            .children()
            .map(entry => getVideoSchema(entry.getAllMetadata())),
        ])
      ),
      image: getSeoImage(content),
    };
  },
  component: CarNewsLanding,
};

export default pageDefinition(pageOptions);
