import { get } from 'lodash';
import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { getCanonical } from 'site-modules/shared/utils/seo-helper';
import { stripHtml } from 'client/utils/string-utils';
import { createDate } from 'client/site-modules/shared/utils/date-utils';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { getOrganizationSchema } from './organization';

export function getAuthorSchema(authorName) {
  return {
    '@type': 'Person',
    name: authorName,
    url: `https://www.edmunds.com/about/authors/${makeNiceName(authorName)}.html`,
  };
}

/**
 *
 * @param {Object} articleObject
 * @param {String} image
 * @param {String} author
 * @param {String} articleType Article or NewsArticle
 * @param {String} articleBody
 * @param {String[]} speakableSelectors
 * @param {String} keywords
 * @return {Object}
 */
export function getArticleSchema({
  articleObject,
  image,
  author,
  articleType = 'Article',
  articleBody,
  speakableSelectors,
  keywords,
}) {
  const { title, subtitle, summary, updated, published, authors } = articleObject;
  const canonical = getCanonical(articleObject);
  const authorName = author || get(authors, '[0].name', '');
  const publisher = getOrganizationSchema({ isTextLogo: articleType === 'NewsArticle' });

  return {
    '@context': SCHEMA_URL,
    '@type': articleType,
    name: title,
    headline: title,
    articleBody: articleBody ? stripHtml(articleBody) : undefined,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': canonical,
    },
    description: summary,
    datePublished: published,
    dateModified: updated,
    alternativeHeadline: subtitle,
    publisher,
    image,
    author: authorName ? getAuthorSchema(authorName) : publisher,
    keywords: keywords || undefined,
    ...(speakableSelectors && {
      speakable: {
        '@type': 'SpeakableSpecification',
        cssSelector: speakableSelectors,
      },
    }),
  };
}

export function getEditorialNewsArticleSchema({ article, author }) {
  const { title, image, url, summary, publishDate, updateDate } = article;

  return {
    '@context': SCHEMA_URL,
    '@type': 'NewsArticle',
    name: title,
    headline: title,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://www.edmunds.com${url}`,
    },
    description: summary,
    datePublished: publishDate,
    dateModified: updateDate,
    publisher: getOrganizationSchema(),
    image,
    author: getAuthorSchema(author),
  };
}

export function getCarNewsArticleSchema(content) {
  const {
    authorName,
    'media-image': image,
    'article-date': date,
    'promo-text': promoText,
    'link-url': url,
    title,
  } = content.getAllMetadata();

  const articleDate = createDate(date);
  const hasPromoText = promoText && promoText !== '&nbsp;';
  const articleTitle = hasPromoText ? promoText : title;

  if (!articleTitle || !url) return null;

  return {
    '@context': SCHEMA_URL,
    '@type': 'NewsArticle',
    name: articleTitle,
    headline: articleTitle,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://www.edmunds.com${url}`,
    },
    datePublished: articleDate,
    dateModified: articleDate,
    publisher: getOrganizationSchema(),
    image,
    author: getAuthorSchema(authorName),
  };
}
