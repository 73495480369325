import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { getStaticLinks, getMakeModelInvLinks } from 'site-modules/shared/utils/link-widget';
import { LinkWidgetsModel, getPageLinksPath, LinkWidgetsEntities } from 'client/data/models/link-widgets';
import { LinkWidgetSectionList } from 'site-modules/shared/components/link-widget-section-list/link-widget-section-list';

import './page-links-widget.scss';

function PageLinksWidgetUI({ linkWidgets, pageName, title, vehicle, inventory, hasTopDivider, isMobile, className }) {
  const staticLinks = getStaticLinks({ pageName, vehicle });
  const makeModelInvLinks = pageName === 'srp-used-make-model' ? getMakeModelInvLinks({ vehicle, inventory }) : null;

  if (!linkWidgets?.length && !staticLinks) return null;

  return (
    <Fragment>
      {hasTopDivider && <hr className="mt-1_5 mb-2" />}
      <section
        className={classnames('page-links-widget link-widget', className)}
        data-tracking-parent="edm-link-widget"
      >
        <h2 className="heading-3 mb-1_5">{title}</h2>
        {/* We use inline styles here to prevent CSL on loading */}
        <div className="links-lists" style={{ columnCount: isMobile ? 1 : 3, columnGap: '24px' }}>
          {makeModelInvLinks && <LinkWidgetSectionList widgetData={makeModelInvLinks} multicolHeading={false} />}
          {!!linkWidgets?.length &&
            linkWidgets.map((section, ind) => (
              <LinkWidgetSectionList
                widgetData={section}
                key={section.header}
                showTitle
                appendPageLinksId
                showSentence={false}
                multicolHeading={false}
                className={ind === linkWidgets.length - 1 ? 'mb-0' : ''}
              />
            ))}
          {staticLinks && <LinkWidgetSectionList widgetData={staticLinks} multicolHeading={false} />}
        </div>
      </section>
    </Fragment>
  );
}

PageLinksWidgetUI.propTypes = {
  linkWidgets: LinkWidgetsEntities.LinkWidgets,
  pageName: PropTypes.string,
  title: PropTypes.string,
  vehicle: PropTypes.shape({
    make: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    model: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
  inventory: PropTypes.arrayOf(PropTypes.shape({})),
  hasTopDivider: PropTypes.bool,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

PageLinksWidgetUI.defaultProps = {
  linkWidgets: null,
  pageName: '',
  title: 'Related information',
  vehicle: null,
  inventory: [],
  hasTopDivider: false,
  isMobile: false,
  className: 'pb-3',
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const propsAreEqual = (prevProps, { linkWidgets }) => !linkWidgets;
export const PageLinksWidget = connect(mapStateToProps)(
  connectToModel(React.memo(PageLinksWidgetUI, propsAreEqual), {
    linkWidgets: bindToPath(getPageLinksPath(), LinkWidgetsModel, null, false),
  })
);
