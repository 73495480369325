import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import Button from 'reactstrap/lib/Button';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './iframe-modal.scss';

export function IframeModal({ isOpen, onToggleModal, iframeCode }) {
  if (!iframeCode) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggleModal}
      contentClassName="iframe-modal-content"
      className="iframe-modal"
      centered
    >
      <ModalHeader className="pos-r pb-2 border-0" onClick={onToggleModal}>
        <Button color="link" className="close-btn px-0_75 display-4">
          <i className="icon-cross2" aria-hidden />
        </Button>
      </ModalHeader>
      <ModalBody className="w-100 h-100 p-0">
        <ContentFragment>{iframeCode}</ContentFragment>
      </ModalBody>
    </Modal>
  );
}

IframeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  iframeCode: PropTypes.string,
};

IframeModal.defaultProps = {
  iframeCode: null,
};
