import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { CommonMap } from 'client/tracking/maps/common';
import { TrackingMap as CarBuyingMap } from 'client/tracking/maps/car-buying/search-by-payment-type';
import { AppraisalTabsMap } from 'client/tracking/maps/appraisal/common/appraisal-tabs';
import { SuggestedVehiclePricingCarouselMap } from 'site-modules/shared/components/suggested-vehicle-pricing-carousel/tracking';
import { LeaderboardMap } from 'client/tracking/maps/editorial/leaderboard';

const map = {
  inline_article_view_content: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_ARTICLE
  ),

  tool_select_view: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SHOW_CALCULATORS
  ),

  tool_select_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SELECT_A_VEHICLE
  ),

  tool_select_input_monthly: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.INPUT_MONTHLY_PAYMENT
  ),

  tool_select_input_vehicle: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.INPUT_VEHICLE_PRICE
  ),

  tool_select_show_loan: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.CAR_LOAN_CALCULATOR
  ),

  tool_select_show_lease: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.CAR_LEASE_CALCULATOR
  ),

  tool_select_show_affordability: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.AFFORDABILITY_CALCULATOR
  ),

  view_author: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'view_author'),

  srp_link: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, 'srp_link'),

  go_to_edmunds_tested_data: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    'go_to_edmunds_tested_data'
  ),

  sort_table: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, 'sort_table'),
};

export const EditorialMap = {
  ...CommonMap,
  ...CarBuyingMap,
  ...map,
  ...SuggestedVehiclePricingCarouselMap,
  ...AppraisalTabsMap,
  ...LeaderboardMap,
};
