import React from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import { getBaseBreadcrumb, getHomeBreadcrumb } from 'site-modules/shared/components/breadcrumbs/helper';
import { edmundsLogoHalfCarLeft } from 'client/images/inline-svgs/edmunds-logo';
import { Breadcrumbs } from 'site-modules/shared/components/breadcrumbs/breadcrumbs';
import { AnchorNavSticky } from 'site-modules/shared/components/anchor-nav-sticky/anchor-nav-sticky';

import './car-news-landing-header.scss';

export function CarNewsLandingHeader({ title, navList, isMobile }) {
  const breadcrumbs = [getHomeBreadcrumb(), getBaseBreadcrumb(title, '/car-news/')];

  return (
    <div className="car-news-landing-header">
      <figure className="pos-a w-100 edmunds-logo-half-car">
        {edmundsLogoHalfCarLeft({ className: 'pos-a top-0 right-0' })}
      </figure>
      <Container className="px-md-0">
        <Breadcrumbs className="pl-0 mb-2 text-white" data={breadcrumbs} />
        <h1 className="heading-1 text-white font-weight-bold mb-md-0_75 mb-lg-0_25 mb-0_25">
          <span className="edmunds-label">Edmunds</span> {title}
        </h1>
        <AnchorNavSticky
          className="pb-1_5"
          anchorNavContentClassName="w-100"
          title={null}
          navList={navList}
          isMobile={isMobile}
          itemClassName="medium py-0_75 pl-0_75"
          linkClassName="py-0_25 px-1"
          hasSeparator={false}
        />
      </Container>
    </div>
  );
}

CarNewsLandingHeader.propTypes = {
  title: PropTypes.string,
  isMobile: PropTypes.bool,
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hash: PropTypes.string,
    })
  ),
};

CarNewsLandingHeader.defaultProps = {
  title: '',
  isMobile: false,
  navList: null,
};
